// modules
import React from "react";
import {Link} from "react-router-dom";

//src
import {GlobalContext} from "./GlobalContext";

//static
import logo from '../media/logo.png'
import axios from "axios";
import {API_BASE} from "../configs";

const Footer = ()=>{
	const {currentPage} = React.useContext(GlobalContext)

	const [visits, setVisits] = React.useState(0)

	React.useEffect( ()=>{
		const hitVisit = async ()=>{
			const {data} = await axios.get(`${API_BASE}/visits`)
			return setVisits(data.count)
		}
		if(currentPage === 'home')
			hitVisit().catch(console.log)
	}, [])

	return <React.Fragment>
		<footer className="footer_area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<div className="footer_top flex-column">
							<div className="footer_logo">
								<a href="/#Home">
									<img src={logo} alt="" />
								</a>
							</div>
							<div className="footer_social">
								<p className="lead">Follow Me</p>
								<a href="https://www.linkedin.com/in/heptadecane" target="_blank" rel="noreferrer">
									<i className="fa-brands fa-linkedin-in"/>
								</a>
								<a href="https://www.github.com/HeptaDecane" target="_blank" rel="noreferrer">
									<i className="fa-brands fa-github"/>
								</a>
								<a href="https://www.twitter.com/hepta_decane" target="_blank" rel="noreferrer">
									<i className="fa-brands fa-twitter"/>
								</a>
								<a href="https://dev.to/heptadecane" target="_blank" rel="noreferrer">
									<i className="fa-brands fa-dev"/>
								</a>
								<a href="https://heptadecane.medium.com" target="_blank" rel="noreferrer">
									<i className="fa-brands fa-medium"/>
								</a>
							</div>
						</div>
					</div>
				</div>
				{currentPage==='home' &&
					<div className="text-center">
						<button className="count btn btn-outline-primary" disabled>
							Visits:{visits}
						</button>
					</div>
				}
				<div className="row footer_bottom justify-content-center">
					<p className="col-lg-8 col-sm-12 footer-text">
						Copyright &copy;{new Date().getFullYear()} All rights reserved by <a>Heptadecane</a>
					</p>
				</div>
			</div>
		</footer>
	</React.Fragment>
}

export default Footer;