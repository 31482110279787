// modules
import React from "react";
import {toast} from "react-toastify";

//src
import {API_BASE} from "../configs";
import axios from "axios";

//static

const NewsLetter = ()=>{
	const [loaders, setLoaders] = React.useState({
		subscribe: false
	})
	const [inputs, setInputs] = React.useState({
		email: ''
	})

	const handleInput = (event)=>{
		setInputs({
			...inputs,
			[event.target.name]: event.target.value
		})
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		setLoaders({
			...loaders,
			subscribe: true
		})

		axios.post(`${API_BASE}/subscribers`, inputs)
			.then(res=>{
				toast.success('Thank you for Subscribing! Check you mail.', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})

				setInputs({
					...inputs,
					email: ''
				})
			})
			.catch(err=>{
				toast.warning(err.response.data, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			})
			.finally(()=>{
				setLoaders({
					...loaders,
					subscribe: false
				})
			})
	}

	return <React.Fragment>
		<a id="NewsLetter"/>
		<section className="newsletter_area">
			<div className="container">
				<div className="row justify-content-center align-items-center">
					<div className="col-lg-12">
						<div className="subscription_box text-center">
							<h2 className="text-uppercase text-white">get updates from Me</h2>
							<p className="text-white">
								Stay notified about upcoming events and changes at Heptadecane <br />(Frankly, I'm just collecting your
								email-ids for no particular reason)
							</p>
							<div className="subcribe-form">
								<form className="subscription relative" onSubmit={handleSubmit}>
									<input name="email" required
								 		placeholder="Email Address" type="email"
							 			value={inputs.email} onChange={handleInput}
									/>
									<button type="submit" className="primary-btn hover d-inline"
										disabled={loaders.subscribe} style={{width:'11rem'}}
									>
										{loaders.subscribe?
											<i className="fa-solid fa-spin-pulse fa-spinner"/>
											:
											<React.Fragment>Count me in</React.Fragment>
										}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</React.Fragment>
}

export default NewsLetter;