// modules
import React from "react";

export const GlobalContext = React.createContext()

export function GlobalContextProvider(props){
	const [currentPage, setCurrentPage] = React.useState("home")

	let context = {
		currentPage: currentPage,
		setCurrentPage: setCurrentPage
	}

	return <GlobalContext.Provider value={context}>
			{props.children}
		</GlobalContext.Provider>
}