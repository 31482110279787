// modules
import React from "react";
import {Link, Navigate} from "react-router-dom";

//src
import {GlobalContext} from "../components/GlobalContext";
import Gallery from "../components/Gallery";
import NewsLetter from "../components/NewsLetter";

//static
import homeRight from '../media/banner/home-right.png'
import aboutMe from '../media/about-me.png'
import aboutWeb from '../media/about-web.png'
import s1 from '../media/services/s1.png'
import s2 from '../media/services/s2.png'
import s3 from '../media/services/s3.png'
import s4 from '../media/services/s4.png'
import {inViewPort} from "../utils";

const Home = ()=>{
	document.title = "Heptadecane"

	const {setCurrentPage} = React.useContext(GlobalContext)
	const [redirect, setRedirect] = React.useState(null)

	React.useEffect(()=>{
		setCurrentPage('home')
	},[setCurrentPage])

	React.useEffect(()=>{
		const element = document.getElementById('header')

		const home = document.getElementById('Home')
		const about1 = document.getElementById('Me')
		const about2 = document.getElementById('Website')
		const about3 = document.getElementById('Interests')
		const newsLetter = document.getElementById('NewsLetter')

		const refs = [
			document.getElementById('One'),
			document.getElementById('Two'),
			document.getElementById('Three')
		]

		const switchActiveRef = (i)=>{
			refs.forEach(ref=>ref.classList.remove('active'))
			refs[i].classList.add('active')
		}

		document.onscroll = (event)=> {
			if (inViewPort(element))
				element.classList.remove('navbar_fixed')
			else
				element.classList.add('navbar_fixed')

			let distances = [
				Math.abs(home.getBoundingClientRect().top),
				Math.min(Math.abs(about1.getBoundingClientRect().top),
					Math.abs(about2.getBoundingClientRect().top),
					Math.abs(about3.getBoundingClientRect().top)
				),
				Math.abs(newsLetter.getBoundingClientRect().top)
			]
			let active_index = distances.indexOf(Math.min(...distances))
			switchActiveRef(active_index)
		}

	},[])

	if(redirect) return <Navigate replace to={redirect} />

	return <React.Fragment>
		<a id="Home"/>
		<section className="home_banner_area">
			<div className="banner_inner">
				<div className="container">
					<div className="row">
						<div className="col-lg-7">
							<div className="banner_content">
								<h3 className="text-uppercase">Hello!</h3>
								<h1 className="text-uppercase">I am <span id="name">Sooraj</span></h1>
								<h5 className="text-uppercase">a Computer Science Novice</h5>
								<div className="d-flex align-items-center">
									<a className="primary_btn" href="">
										<span>Get in Touch</span>
									</a>
									<a className="primary_btn tr-bg" target="_blank"
										 href="https://heptadecane.github.io/store/Sooraj_VS_Resume.pdf" rel="noreferrer"
									>
										<span>&emsp; Get CV &emsp;</span>
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="home_right_img">
								<img className="" src={homeRight} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<a id="Me"/>
		<section className="about_area section_gap">
			<div className="container">
				<div className="row justify-content-start align-items-center">
					<div className="col-lg-5">
						<div className="about_img">
							<img className="" src={aboutMe} alt="" />
						</div>
					</div>

					<div className="offset-lg-1 col-lg-5">
						<div className="main_title text-left">
							<h2>let me Introduce myself</h2>
							<p className="paragraph">
								Hey! My name is Sooraj and I'm a final year Computer Engineering disciple at Pune Institute of Computer
								Technology. I was introduced to this field during my High School, where I learned to write C++ Code
								Snippets from simple logic and arithmetics, which invoked my interests in Problem Solving and
								Algorithms, from there I continued pursuing it for academics and expanded my knowledge in this field.
							</p>
							<p className="paragraph">
								During past one or half year I found myself interested towards Web Development that's how I started
								learning a technology stack related to web dev, and I got to know Web Development has much to explore, I
								plan to dive deep into this sector of IT to find where it takes me.
							</p>
							<a className="primary_btn" target="_blank" rel="noreferrer" href="https://heptadecane.github.io/store/Sooraj_VS_Resume.pdf">
								<span>Download CV</span>
							</a>
						</div>
					</div>
				</div>
			</div>

			<a id="Website"/>
			<div className="container">
				<div className="row justify-content-start align-items-center">

					<div className="offset-lg-1 col-lg-5">
						<div className="main_title text-left">
							<h2>What is ths Website?</h2>
							<p className="paragraph">Well, I was recollecting some fragments of Django (a Web Framework), which I
								planned to go like, one App per topic and each time I made progress, I was left with Django's Default
								Landing Page, which was sort of boring to look at. I took some time to make a "basic version" of this
								page for routing to my paths and used it as my landing page instead.</p>
							<p className="paragraph"> So, When I started practicing Deployment on Cloud platform with a domain name,
								this page was getting hosted globally, hence, I decided to modify the "basic version" to "what you're
								seeing now".<br/><span className="font-weight-bold">TL;DR,</span> this is a landing page I made during those Django practice sessions.
							</p>
							<h2>What's Heptadecane?</h2>
							<p className="paragraph">
								Heptadecane is an organic compound, an alkane hydrocarbon with the chemical formula C₁₇H₃₆. It is a
								component of essential oils from plants like Opuntia littoralis and Annona squamosa. It has a role as a
								plant metabolite and a volatile oil component, and the word 'Heptadecane' itself fills me with energy
								for rest of the day.
							</p>
							<p className="paragraph">
								well, that was a bluff the truth is, it was nearly impossible for me to think of a domain name. So, I
								used my Birthdate i.e. 7th of October which can be written as 7-10 and ultimately transformed into
								'hepta'-'deca'. Now to combine both I thought of "Heptadecane" which sounded less weird than
								"Heptadecagon", and Bam! here we are with a Name and a Logo.
							</p>
						</div>
					</div>
					<div className="col-lg-5">
						<div className="about_img" style={{left:"250px"}}>
							<img className="" src={aboutWeb} alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>

		<a id="Interests"/>
		<section className="features_area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8 text-center">
						<div className="main_title">
							<h2>Areas of Interest</h2>
							<p>
								During this long academic run I stumbled upon a variety of domains and subs, though these are a few
								topics on which I am curious about and wants to spend some more time exploring.
							</p>
						</div>
					</div>
				</div>
				<div className="row feature_inner">
					<div className="col-lg-3 col-md-6">
						<div className="feature_item">
							<img src={s1} alt="" />
								<h4>Problem Solving</h4>
								<p>Act of determining the cause of the problem, beaking it and implementing a solution with alternatives
									keeps me going.</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="feature_item">
							<img src={s2} alt="" />
								<h4>Data Structures</h4>
								<p>Data structures play a major role in development, the ability choose and implement the right one
									gives you an edge. </p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="feature_item">
							<img src={s3} alt="" />
								<h4>Web design</h4>
								<p>This is the development part of me, I am eager to learn backend technologies currently in
									production.</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="feature_item">
							<img src={s4} alt="" />
								<h4>Mathematics</h4>
								<p>Why not? Essential for an evolving world, Language of Universe, will never fade away and countless
									reasons.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<Gallery/>

		<a id="NewsLetter"/>
		<NewsLetter/>

	</React.Fragment>
}

export default Home