import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import {GOOGLE_MAPS_API_KEY} from "../configs";

const MapBox = ({coordinates})=>{
	const [loaders, setLoaders] = React.useState({
		marker: true
	});

	React.useEffect(() => {
		setTimeout(() => {
			setLoaders({
				...loaders,
				marker: false
			})
		}, 500);
	}, []);

	return <React.Fragment>
		<div className="container">
			<LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
				<GoogleMap mapContainerStyle={{height: '500px'}} center={coordinates} zoom={17}>
					{loaders.marker===false && <Marker position={coordinates}/>}
				</GoogleMap>
			</LoadScript>
		</div>
	</React.Fragment>
}

export default MapBox