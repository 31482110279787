// modules
import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {ToastContainer} from "react-toastify";

// src
import {GlobalContextProvider} from "./components/GlobalContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Contact from "./pages/Contact";


// static
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './vendors/linericon/style.css';
import './vendors/owl-carousel/owl.carousel.min.css';
import './styles/magnific-popup.css';
import './styles/ghost-box.css'
import './vendors/nice-select/css/nice-select.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import './styles/photo-album.css'

function App() {
	return <React.Fragment>
		<GlobalContextProvider>
			<Router>
				<Header/>
				<Routes>
					<Route exact path='/' element={<Home/>} />
					<Route exact path='/contact' element={<Contact/>} />
					<Route path='*' element={<NotFound/>} />
				</Routes>
				<Footer/>
			</Router>
		</GlobalContextProvider>
		<ToastContainer/>
	</React.Fragment>
}

export default App;
