export const randomInt = (min, max)=>{
	// returns a random number between min and max (both included):
	return Math.floor(Math.random() * (max - min + 1) ) + min;
}

export const inViewPort = (element)=>{
	let elementHeight = element.offsetHeight;
	let elementWidth = element.offsetWidth;
	let bounding = element.getBoundingClientRect()
	return (
		bounding.top >= -elementHeight &&
		bounding.left >= -elementWidth &&
		bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + elementWidth &&
		bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + elementHeight
	)
}