// modules
import React from "react";
import {Link} from "react-router-dom";

//src
import {GlobalContext} from "./GlobalContext";
import {inViewPort} from "../utils";

//static
import logo from '../media/logo.png'

const Header = ()=>{
	const {currentPage} = React.useContext(GlobalContext)


	React.useEffect(()=>{
		const element = document.getElementById('header')
		document.onscroll = (event)=> {
			if (inViewPort(element))
				element.classList.remove('navbar_fixed')
			else
				element.classList.add('navbar_fixed')
		}
	},[])

	return <React.Fragment>
		<header className="header_area" id="header">
			<div className="main_menu">
				<nav className="navbar navbar-expand-lg navbar-light">
					<div className="container">
						<Link className="navbar-brand logo_h" to='/'>
							<img src={logo} alt="" />
						</Link>
						<button className="navbar-toggler" type="button" data-toggle="collapse"
							data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation"
						>
							<span className="icon-bar"/>
							<span className="icon-bar"/>
							<span className="icon-bar"/>
						</button>

						<div className="collapse navbar-collapse offset" id="navbarSupportedContent">
							<ul id="navBar" className="nav navbar-nav menu_nav justify-content-end">
								<li className={`nav-item ${currentPage==='home' && 'active'}`} id="One">
									<a className="nav-link" href="/#Home">
										Home
									</a>
								</li>
								<li className="nav-item submenu dropdown" id="Two">
									<a href="#" className="nav-link dropdown-toggle"
										 data-toggle="dropdown" role="button" aria-haspopup="true"
										 aria-expanded="false" id="navbarDropdown"
									>
										About
									</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
										<li className="nav-item"><a className="nav-link" href="/#Me">Me</a></li>
										<li className="nav-item"><a className="nav-link" href="/#Website">Website</a></li>
									</ul>
								</li>
								<li className="nav-item" id="Three">
									<a className="nav-link" href="/#NewsLetter">newsletter</a></li>
								<li className={`nav-item ${currentPage==='contact' && 'active'}`} id="Four">
									<Link className="nav-link" to="/contact">Contact me</Link>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</div>
		</header>
	</React.Fragment>
}

export default Header