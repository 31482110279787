import React from "react";
import axios from "axios";

import {randomInt} from "../utils";
import {API_BASE} from "../configs";

const Gallery = ()=>{
	const [pastimes, setPastimes] = React.useState([])
	
	const [gridParams, setGridParams] = React.useState({
		rowStart: -25,
		colStart: 0,
		length: 36,
		height: 65,
		colStep: null,
		rowStep: null
	})

	React.useEffect(()=>{
		const getPastimes = async()=>{
			const {data} = await axios.get(`${API_BASE}/pastimes`)
			data.sort(()=>Math.random()-0.5)
			setPastimes(data)

			const n = data.length
			const {length, height} = gridParams
			if(n > 0){
				setGridParams({
					...gridParams,
					cols: Math.ceil(Math.sqrt(n)),
					colStep: length/(Math.ceil(Math.sqrt(n))-1),
					rowStep: height/(Math.ceil(Math.sqrt(n))-1)
				})
			}
		}
		getPastimes().catch(console.log)
	},[])

	const {rowStart, colStart, rowStep, colStep, cols} = gridParams

	return <React.Fragment>
		<section className="portfolio_area" id="portfolio">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="main_title text-left">
							<h2>bits and pieces i'm into... 🎧 </h2>
							<p>
								These are some of my favourites when it's about killing time.
								<br />
									Lets see if you can relate to me!
							</p>
						</div>
					</div>
				</div>

				{/*
					cols = sqrt(n)
					colStep = length/sqrt(n)
					rowStep = height/sqrt(n)

					i = rowStart + i/cols * rowStep
					j = colStart + i%cols * colStep
				*/}

				<div className="photo-album">
					{pastimes.map((pastime, i)=><React.Fragment key={pastime._id}>
							<div
								style={{
									top:`${rowStart + Math.floor(i/cols)*rowStep}%`,
									left:`${colStart + i%cols*colStep}%`
								}}
								className={`medium polaroid rot${randomInt(0,19)}`}
							>
								<img src={pastime.cover} alt="" />
								<p>
									<span style={{textTransform:"uppercase"}} className="font-weight-bold">
										{pastime.title}
									</span>
									{pastime.description &&
										<React.Fragment>
											<br/>{pastime.description}
										</React.Fragment>
									}
								</p>
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		</section>
	</React.Fragment>
}

export default Gallery;