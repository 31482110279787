// modules
import React from "react";
import {Link, Navigate} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";


// src
import {GlobalContext} from "../components/GlobalContext";
import {API_BASE} from "../configs";
import MapBox from "../components/MapBox";
import {inViewPort} from "../utils";


// static

const Contact = ()=> {
	document.title = "Contact Me"

	const {setCurrentPage} = React.useContext(GlobalContext)

	const [redirect, setRedirect] = React.useState(null)
	const [contact, setContact] = React.useState({})
	const [loaders, setLoaders] = React.useState({
		sendMail: false,
		contact: true
	})
	const [inputs, setInputs] = React.useState({
		name: '', email: '', subject: '', text: ""
	})

	React.useEffect(()=>{
		setCurrentPage('contact')
	},[setCurrentPage])

	React.useEffect(()=>{
		const element = document.getElementById('header')
		document.onscroll = (event)=> {
			if (inViewPort(element))
				element.classList.remove('navbar_fixed')
			else
				element.classList.add('navbar_fixed')
		}

		const refs = [
			document.getElementById('One'),
			document.getElementById('Two'),
			document.getElementById('Three')
		]
		refs.forEach(ref => ref.classList.remove('active'))

	},[])

	React.useEffect(()=>{
		const getActiveContact = async()=>{
			const {data} = await axios.get(`${API_BASE}/contacts/active`)
			setContact(data)

			if(data)
				setLoaders({
				...loaders,
				contact: false
			})
		}
		getActiveContact().catch(console.log)
	},[])

	const handleInput = (event)=>{
		setInputs({
			...inputs,
			[event.target.name]: event.target.value
		})
	}

	const handleSubmit = (event)=>{
		event.preventDefault()
		setLoaders({
			...loaders,
			sendMail: true
		})

		axios.post(`${API_BASE}/feedback`, inputs)
			.then(res=>{
				toast.success('We\'re delighted to here from you, will get back to You soon.', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})

				setInputs({
					...inputs,
					name: '', email: '', subject: '', text: ""
				})
			})
			.catch(err=>{
				toast.warning(err.response.data, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			})
			.finally(()=>{
				setLoaders({
					...loaders,
					sendMail: false
				})
			})
	}


	if(redirect) return <Navigate replace to={redirect} />

	return <React.Fragment>

		<section className="banner_area">
			<div className="banner_inner d-flex align-items-center">
				<div className="container">
					<div className="banner_content text-center">
						<h2>Contact Me</h2>
					</div>
				</div>
			</div>
		</section>
		{loaders.contact===false &&
			<section className="contact_area section_gap">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="contact_info">
								<div className="info_item">
									<i className="lnr lnr-home"/>
									<h6>{contact.city}, {contact.state}</h6>
									<p>{contact.country} ({contact.pin})</p>
								</div>
								<div className="info_item">
									<i className="lnr lnr-phone-handset"/>
									<h6><a href="#">{contact.phone}</a></h6>
									<p>Mon to Fri 9am to 6 pm</p>
								</div>
								<div className="info_item">
									<i className="lnr lnr-envelope"/>
									<h6>
										<a href={`mailto:${contact.email}?subject=feedback`} target="_blank" rel="noreferrer">
											{contact.email}
										</a>
									</h6>
									<p>Send me your feedback anytime!</p>
								</div>
							</div>
						</div>
						<div className="col-lg-9">
							<h4 className="text-center">Queries or Feedback?</h4>
							<br/>
							<form className="row contact_form" id="contactForm" onSubmit={handleSubmit}>
								<div className="col-md-6">
									<div className="form-group">
										<input type="text" required className="form-control"
													 id="name" name="name" placeholder="Enter your name"
													 value={inputs.name} onChange={handleInput}
										/>
									</div>
									<div className="form-group">
										<input type="email" required className="form-control"
													 id="email" name="email" placeholder="Enter email address"
													 value={inputs.email} onChange={handleInput}
										/>
									</div>
									<div className="form-group">
										<input type="text" required className="form-control"
													 id="subject" name="subject" placeholder="Enter Subject"
													 value={inputs.subject} onChange={handleInput}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
								<textarea className="form-control" required name="text"
													id="text" rows="1" placeholder="Enter Message"
													value={inputs.text} onChange={handleInput}
								/>
									</div>
								</div>
								<div className="col-md-12 text-right">
									<button type="submit" value="submit" className="primary_btn"
													disabled={loaders.sendMail} style={{width:'14rem'}}
									>
										{loaders.sendMail ?
											<i className="fa-solid fa-spin-pulse fa-spinner fa-lg"/>
											:
											<span>Send Message</span>
										}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		}

		{loaders.contact===false && <MapBox coordinates={contact.coordinates}/>}

	</React.Fragment>
}

export default Contact;